<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="showModal = true"
            >
              <md-icon>add</md-icon>
              <md-tooltip>Create News</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <NewsModal
      v-if="showModal"
      :item="selectedItem"
      @close="closeModal"
      @saved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import NewsModal from './NewsModal.vue';

export default {
  components: {
    VueTable,
    NewsModal,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'app_news_id',
            mask: 'ID',
            noFormat: true,
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'is_pinned',
            mask: 'is pinned',
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show News Details',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete News',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showModal: false,
      selectedItem: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.request('app/news', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onDelete(item) {
      this.request(`app/news/${item.app_news_id}`, 'delete', null, () => {
        this.fireSuccess('News deleted successfully');
        this.$refs.vtable.init();
      });
    },
    closeModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
  },
};
</script>

<style scoped>
</style>
