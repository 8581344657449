<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      NEWS FORM
    </template>
    <template slot="body">
      <md-switch
        v-model="form.is_pinned"
        class="pb-3"
      >
        Is Pinned
      </md-switch>

      <FormText
        v-model="form.title"
        icon="description"
        label="Title"
      />
      <div>
        <trumbowyg
          v-model="form.body"
          :config="config"
          class="form-control"
          name="resultHtml"
        />
      </div>
      <img
        v-if="form.media_url"
        :src="form.media_url"
        class="imgPreview"
      >
      <form-uploader
        :max-size="5*1024"
        class="text-center"
        @error="fireError"
        @input="onFileUploaded"
      >
        <md-button
          class="md-primary md-sm"
        >
          Choose File
        </md-button> <br>
        <b
          v-if="form.file"
          class="text-success"
        >{{ form.file.name }}</b>
      </form-uploader>
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveForm"
      >
        SAVE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText, FormUploader } from '@/components/Inputs';

export default {
  components: {
    FormText,
    FormUploader,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    config: {
      btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
      ],
      semantic: {
        div: 'div', // Editor does nothing on div tags now
      },
    },
    form: {
      title: null,
      body: null,
      file: null,
      is_pinned: false,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onFileUploaded(file) {
      this.form.file = file;
      this.form.media_url = URL.createObjectURL(file);
    },
    saveForm() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('title', this.form.title);
      formData.append('body', this.form.body);
      formData.append('is_pinned', this.form.is_pinned);

      if (this.form.app_news_id) {
        this.request(`app/news/${this.form.app_news_id}`, 'put', formData, () => {
          this.fireSuccess('News Updated Successfully');
          this.$emit('saved');
          this.close();
        });
      } else {
        this.request('app/news', 'post', formData, () => {
          this.fireSuccess('News Created Successfully');
          this.$emit('saved');
          this.close();
        });
      }
    },
  },
};
</script>
<style scoped>
.imgPreview {
  max-width: 300px;
  margin: 1em auto .5em;
  display: block;
}
</style>
